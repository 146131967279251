<template>
  <v-container
    id="user-profile-view"
    fluid
    tag="section"
  >
    <snack-bar ref="snackBar" />
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          color="primary"
          icon="mdi-account-outline"
        >
          <template #title>
            Edit Profile — <small class="text-body-1">Complete your profile</small>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.name"
                    color="purple"
                    label="Name"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.email"
                    disabled
                    color="purple"
                    label="Email Address"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.slack_id"
                    color="purple"
                    label="Slack Id"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="user.sos_list_name"
                    color="purple"
                    label="SOS List Name"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="user.description"
                    color="purple"
                    rows="2"
                    label="Description"
                  />
                </v-col>
                <v-divider></v-divider>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="user.settings.time_zone"
                    :items="timezoneList"
                    color="purple"
                    label="Your Working Area Timezone"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="user.settings.language"
                    :items="['english']"
                    color="purple"
                    label="System Language"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-switch
                    v-model="user.settings.dark_mode"
                    label="Dark Model"
                  ></v-switch>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-switch
                    v-model="user.settings.sidebar_mini"
                    label="sidebar Menu"
                  ></v-switch>
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    min-width="150"
                    @click="updateProfile"
                  >
                    Update Profile
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <material-card
          title="Follow Receipt"
        >
          <v-data-table
            :headers="receiptHeaders"
            :items="receiptData"
          >
          </v-data-table>
        </material-card>
        <material-card
          title="Follow Receipt Item"
        >
          <v-data-table
            :headers="receiptItemHeaders"
            :items="receiptItemData"
          >
          </v-data-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, sync } from 'vuex-pathify'
  import userApi from '@/api/user'
  import MaterialCard from '@/components/MaterialCard'
  import SnackBar from '@/components/app/SnackBar'
  import store from '@/store'
  export default {
    name: 'UserProfileView',
    components: { SnackBar, MaterialCard },
    data () {
      return {
        user: {
          name: '',
          email: '',
          sos_list_name: '',
          slack_id: '',
          description: '',
          settings: {
            time_zone: '',
            language: '',
            dark_mode: false,
            sidebar_mini: false,
          },
        },
        timezoneList: ['UTC', 'America/Chicago', 'America/Los_Angeles', 'Asia/Shanghai'],
        receiptHeaders: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Receipt #', value: 'po_number' },
          { text: 'Category', value: 'category.name' },
          { text: 'Status', value: 'task_status.name' },
        ],
        receiptData: [],
        receiptItemHeaders: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Receipt #', value: 'receipt_number' },
          { text: 'Part #', value: 'item_part_number' },
          { text: 'Category', value: 'category.name' },
          { text: 'Status', value: 'task_status.name' },
        ],
        receiptItemData: [],
      }
    },
    mounted () {
      this.loadAuthUser()
    },
    methods: {
      loadAuthUser () {
        store.set('message/storeMessage', {
          color: 'info',
          text: 'Data on the way',
          timeout: 3000,
        })
        this.$refs.snackBar.activeStatusInfo()
        userApi.loginUser().then(response => {
          this.user = response.data.data
          this.receiptData = response.data.data.follow_receipt
          this.receiptItemData = response.data.data.follow_receipt_item
        })
      },
      updateProfile () {
        userApi.update(this.user).then(() => {
          this.loadAuthUser()
        }).catch(error => {
          console.log(error)
        })
      },
    },
  }
</script>
